(<template>
  <div class="assignment-offer">
    <button v-if="youInvited || canBeHidden"
            :class="{'assignment-offer__decline-btn': youInvited, 'assignment-offer__hide-btn': canBeHidden}"
            @click.prevent.stop="setAction">
      <div class="assignment-offer__action-btn-icon"></div>
      <p>{{ actionBtnText }}</p>
    </button>
  </div>
</template>)

<script>
  import {mapGetters, mapActions} from 'vuex';

  export default {
    props: {
      assignInfo: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter'
      ]),
      jobStatus() { return this.assignInfo.status; },
      youInvited() {
        return this.jobStatus == 'invited' && this.userIsInterpreter;
      },
      canBeHidden() {
        return this.jobStatus == 'cancelled';
      },
      actionBtnText() {
        return this.youInvited ? this.$gettext('Decline') : this.$gettext('Hide');
      }
    },
    methods: {
      ...mapActions('OneAssignmentStore', [
        'makeHide',
        'makeAbandon'
      ]),
      setAction() { this.youInvited ? this.catchDecline() : this.catchHide(); },
      modalCallback() { window.location.reload(); },
      catchDecline() {
        this.$emit('startprogress');
        this.makeAbandon(this.assignInfo.id).then(() => {
          this.$emit('stopprogress');
        }).catch((error) => {
          this.$emit('stopprogress');
          if (error.data
            && error.data.errors.length
            && error.data.errors.includes('Unallowed to abandon')) {
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('You have already declined this invitation.'),
                method: 'modalCallback',
                context: this
              });
            }, 0);
          }
        });
      },
      catchHide() {
        this.$emit('startprogress');
        this.makeHide(this.assignInfo.discussionId).then(() => {
          this.$emit('stopprogress');
        }).catch(() => {
          this.$emit('stopprogress');
        });
      }
    }
  };
</script>

<style scoped>
  .assignment-offer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
  }

  .assignment-offer__decline-btn,
  .assignment-offer__hide-btn {
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
  }

  .assignment-offer__decline-btn:hover p,
  .assignment-offer__hide-btn:hover p {
    text-decoration: underline;
  }

  .assignment-offer__decline-btn {
    background-color: #fd497c;
    color: #fff;
  }

  .assignment-offer__action-btn-icon {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-image: url(~@assets/imgs/undefined_imgs/decline_icon.svg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .assignment-offer__hide-btn {
    background-color: #a7abbd;
    color: #fff;
  }
</style>
