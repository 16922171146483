(<template>
  <router-link v-touch:swipe="swipeLogic"
               :to="$makeRoute(assignmentRoute)"
               :class="{'assignment__cont': isOfferBlock}"
               class="assignment__wrapper assignment__link">
    <section :class="[assignStatus.class, {'in-progress': activeProgress}]"
             class="assignment is-progress-bar">
      <div class="assignment__date-cont">
        <div class="assignment__day-name">{{ dayName }}</div>
        <div class="assignment__day-number">{{ dayNumber }}</div>
        <div class="assignment__month-name">{{ monthName }}</div>
      </div>
      <div class="assignment__info-cont">
        <p class="assignment__subject-info"><span>{{ assignStatus.text }}</span> {{ assignTitle }}</p>
        <p class="assignment__lang-info">
          <span>{{ languageTo }}</span>
        </p>
        <p class="assignment__time">{{ assignmentHours }}</p>
      </div>
      <button class="assignment__type-cont"
              tabindex="-1">
        <div :class="[assignTypeClass]"
             class="assignment__type-icon assignment-type-btn"></div>
        <p class="assignment__status-text">{{ assignTypeText }}</p>
      </button>
      <assignment-offer v-if="isOfferBlock && (swipeLeft || desktopView)"
                        :class="{'active': !desktopView}"
                        :assign-info="assignInfo"
                        @startprogress="activeProgress = true"
                        @stopprogress="activeProgress = false" />
    </section>
  </router-link>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import helpers from '@/helpers';
  import AssignmentOffer from '@/components/assignment_components/lists/AssignmentOffer';

  export default {
    components: {
      'assignment-offer': AssignmentOffer
    },
    props: {
      assignInfo: {
        type: Object,
        default() {
          return {};
        }
      },
      showOffer: {
        type: Boolean
      }
    },
    data() {
      return {
        activeProgress: false,
        swipeLeft: false,
        desktopView: true
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
        'userIsNotInterpreter'
      ]),
      ...mapGetters('FeatureFlagsStore', ['enableFaceliftedDesign']),
      languageTo() {
        const langTo = this.$getLangName(this.assignInfo.langToId);
        const alternativeLangTo = this.$getLangName(this.assignInfo.alternativeLanguageToId);

        return langTo && alternativeLangTo ? `${langTo} / ${alternativeLangTo}` : langTo;
      },
      subject() { return this.assignInfo.subject || ''; },
      category() { return this.assignInfo.category || ''; },
      jobStatus() { return this.assignInfo.status; },
      titleText() {
        const title = this.subject || this.category;
        return this.subject && this.category ? `${this.category}: ${this.subject}` : title;
      },
      assignTitle() { return `#${helpers.getJobInfo.jobId(this.assignInfo)} - ${this.titleText}`; },
      assignmentType() { return this.assignInfo.sessionType; },
      isAcceptedJob() { return this.jobStatus == 'accepted'; },
      isNeedsFeedbackJob() { return this.jobStatus == 'needsFeedback'; },
      assignTypeClass() {
        const isFacelifted = this.enableFaceliftedDesign;

        const typeMap = {
          video: 'video',
          phone: 'phone',
          in_person: 'in-person',
          videoroom: 'video-room'
        };

        const type = typeMap[this.assignmentType];

        if (!isFacelifted) {
          const isGreen = this.isAcceptedJob
            || this.isNeedsFeedbackJob
            || this.jobStatus === 'finished'
            || this.jobStatus === 'in_progress'
            || this.jobStatus === 'needsRate';

          return !isGreen ? `assignment-type-btn--${type}-grey` : `assignment-type-btn--${type}-green`;
        }

        const isConfirmed = this.isAcceptedJob || this.jobStatus === 'in_progress';
        const isFinishedOrClosedWithInterpreter = this.assignInfo?.interpreter && (this.jobStatus === 'finished' || this.jobStatus === 'closed');
        const isCancelled = this.jobStatus === 'cancelled';

        if (isCancelled) {
          return `assignment-type-btn--${type}-red`;
        }

        if (isFinishedOrClosedWithInterpreter || this.jobStatus === 'needsRate' || this.jobStatus === 'needsFeedback') {
          return `assignment-type-btn--${type}-dark-grey`;
        }

        if (isConfirmed) {
          return `assignment-type-btn--${type}-green`;
        }

        return `assignment-type-btn--${type}-grey`;
      },
      assignTypeText() {
        if (this.isAcceptedJob || this.isNeedsFeedbackJob) {
          if (this.userIsInterpreter) {
            return this.assignInfo && this.assignInfo.owner ? this.assignInfo.owner.split(' ')[0] : '';
          }
          if (this.userIsNotInterpreter) {
            return this.assignInfo && this.assignInfo.interpreter ? this.assignInfo.interpreter.split(' ')[0] : '';
          }
        } else {
          switch (this.assignmentType) {
            case 'video':
              return this.$gettext('Video');
            case 'phone':
              return this.$gettext('Phone');
            case 'in_person':
              return this.$gettext('In-person');
            case 'videoroom':
              return this.$gettext('Video room');
          }
        }
      },
      assignStatus() {
        return helpers.getJobInfo.jobStatusObj(this, this.jobStatus);
      },
      startTime() {
        return this.$moment(this.assignInfo.startTime);
      },
      finishTime() {
        return this.$moment(this.assignInfo.finishTime);
      },
      deadline() {
        return this.assignInfo.deadline ? this.$moment(this.assignInfo.deadline) : '';
      },
      date() {
        return this.deadline || this.startTime;
      },
      dayNumber() {
        return this.date.date();
      },
      dayName() {
        return this.$helperData.getDayNames(this)[this.date.day()].slice(0, 3).toUpperCase();
      },
      monthName() {
        return this.$helperData.getMonthNames(this)[this.date.month()].slice(0, 3).toUpperCase();
      },
      assignmentHours() {
        return this.deadline
          ? this.deadline.format('HH:mm')
          : `${this.startTime.format('HH:mm')} - ${this.finishTime.format('HH:mm')}`;
      },
      isOfferBlock() {
        return this.showOffer && (this.jobStatus == 'published' || this.jobStatus == 'invited' || this.jobStatus == 'cancelled');
      },
      assignmentRoute() {
        return {
          name: this.userIsInterpreter ? 'ServerOneAssignment' : 'BuyerOneAssignment',
          params: {
            id: this.assignInfo.id
          }
        };
      }
    },
    methods: {
      swipeLogic(event) {
        this.swipeLeft = event === 'left';
      }
    },
    created() {
      this.desktopView = !this.$store.state.GlobalStore.touchScreen;
    }
  };
</script>

<style>
  .assignment__cont:hover .assignment-offer,
  .assignment-offer.active {
    display: flex;
  }
</style>

<style scoped>
  .assignment {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    border-top: 1px solid #e9ebef;
    cursor: pointer;
  }

  .assignment:hover {
    background-color: #f4f6f8;
  }

  .assignment__yellow-status .assignment__subject-info span {
    color: var(--assignment-type-yellow-status-color);
    font-weight: bold;
  }

  .assignment__green-status .assignment__subject-info span {
    color: var(--assignment-type-green-status-color);
    font-weight: bold;
  }

  .assignment__purple-status .assignment__subject-info span {
    color: var(--assignment-type-purple-status-color);
    font-weight: bold;
  }

  .assignment__red-status .assignment__subject-info span {
    color: var(--assignment-type-red-status-color);
    font-weight: bold;
  }

  .assignment__dark-blue-status .assignment__subject-info span {
    color: var(--assignment-type-dark-blue-status-color);
    font-weight: bold;
  }

  .assignment__wrapper:focus > section {
    background-color: #f4f6f8;
  }

  .assignment__link:last-of-type .assignment {
    border-bottom: 1px solid #e9ebef;
  }

  .assignment__date-cont {
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 65px;
    padding: 17px 10px 18px 5px;
  }

  .assignment__date-cont::after {
    content: '';
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 10px;
    display: block;
    width: 1px;
    background-color: #e9ebef;
  }

  .assignment__day-number {
    margin: 0;
    font-size: 20px;
    line-height: 1;
  }

  .assignment__day-name,
  .assignment__month-name {
    margin: 0;
    font-size: 12px;
    line-height: 1;
  }

  .assignment__info-cont {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex-wrap: wrap;
    align-self: center;
    width: calc(100% - 75px - 100px);
    padding: 10px;
    font-size: 12px;
    line-height: 1.4em;
  }

  .assignment__time {
    width: 100%;
    font-weight: bold;
  }

  .assignment__lang-info {
    overflow: hidden;
    margin-bottom: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .assignment__subject-info {
    display: block;
    overflow: hidden;
    width: 100%;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .assignment__type-cont {
    display: flex;
    flex-grow: 2;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 75px;
    max-width: 75px;
  }

  .assignment__type-icon {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    cursor: pointer;
  }

  .assignment__status-text {
    display: inline-block;
    width: auto;
    margin: 0;
    font-weight: bold;
    font-size: 12px;
  }

  @media (max-width: 1023px) {
    .assignment {
      min-height: 35px;
    }

    .assignment__type-icon {
      margin: 5px 15px 0;
    }

    .assignment__info-cont {
      width: calc(100% - 160px - 60px);
    }

    .assignment__lang-info {
      margin-right: 20px;
    }

    .assignment__subject-info {
      width: 100%;
      font-size: 12px;
    }
  }

  @media (max-width: 767px) {
    .assignment {
      min-height: 70px;
    }

    .assignment__info-cont {
      width: calc(100% - 100px - 70px);
    }

    .assignment__lang-info {
      margin-right: 0;
    }

    .assignment__subject-info {
      width: 100%;
      font-size: 10px;
    }
  }

  @media (max-width: 420px) {
    .assignment__type-icon {
      margin: 10px 15px 0;
    }
  }
</style>
