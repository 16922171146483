(<template>
  <div v-if="assignments.length"
       class="assignments-list">
    <assignment v-for="assignment in assignments"
                :key="assignment.id"
                :assign-info="assignment"
                :show-offer="showOffer" />
    <router-link v-if="showAllBtn.display"
                 :to="assignmentsLink"
                 class="sk-btn sk-btn--white assignments-list__btn">{{ viewAllText }}</router-link>
  </div>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import AssignmentForList from '@/components/assignment_components/lists/AssignmentForList';

  export default {
    components: {
      assignment: AssignmentForList
    },
    props: {
      assignmentsList: {
        type: Array,
        default: () => []
      },
      showAllBtn: {
        type: Object,
        default: () => {
          return {
            display: false,
            link: ''
          };
        }
      },
      showOffer: Boolean
    },
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter']),
      ...mapState('b_DashboardStore', {
        myAssignmentsStatistic: ({myAssignmentsStatistic}) => myAssignmentsStatistic || {},
        searchQuery: ({myAssignments}) => myAssignments.searchQuery
      }),
      ...mapState('DashboardSearchStore', {searchQuery: ({searchQuery}) => searchQuery}),
      viewAllText() { return this.$gettext('View all assignments'); },
      isDeclinedView() { return this.$route.query.view === 'declined'; },
      isActiveSearch() { return this.$route.query.searchQuery || this.searchQuery; },
      assignments() {
        const defaultAssignmentList = this.assignmentsList?.length ? this.assignmentsList : [];
        return (this.isDeclinedView || this.isActiveSearch)
          ? defaultAssignmentList
          : defaultAssignmentList.filter((assignment) => assignment.status !== 'cancelled');
      },
      serverAssignmentsLink() {
        return this.$makeRoute({
          name: 'ServerAllAssignments',
          query: {
            view: 'current',
            sortConfirmedBy: 1,
            sortAvailableBy: 1,
            pageConfirmedBy: 1,
            pageAvailableBy: 1
          }
        });
      },
      buyerAssignmentsLink() {
        return this.$makeRoute({
          name: 'BuyerAllAssignments',
          query: {
            view: 'current',
            sort: 1,
            page: 1
          }
        });
      },
      assignmentsLink() {
        return this.showAllBtn && this.showAllBtn.link
          ? this.showAllBtn.link
          : this.userIsInterpreter
            ? this.serverAssignmentsLink
            : this.buyerAssignmentsLink;
      }
    }
  };
</script>

<style scoped>
  .assignments-list {
    display: block;
    overflow: auto;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-bottom: 2px;
    list-style-type: none;
  }

  .assignments-list__btn {
    margin-top: 20px;
    padding: 0 15px;
  }
</style>
