// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/decline_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.assignment-offer[data-v-f8e0ebc8] {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  display: none;\n}\n.assignment-offer__decline-btn[data-v-f8e0ebc8],\n.assignment-offer__hide-btn[data-v-f8e0ebc8] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  width: 100px;\n}\n.assignment-offer__decline-btn:hover p[data-v-f8e0ebc8],\n.assignment-offer__hide-btn:hover p[data-v-f8e0ebc8] {\n  text-decoration: underline;\n}\n.assignment-offer__decline-btn[data-v-f8e0ebc8] {\n  background-color: #fd497c;\n  color: #fff;\n}\n.assignment-offer__action-btn-icon[data-v-f8e0ebc8] {\n  display: block;\n  width: 25px;\n  height: 25px;\n  border-radius: 50%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-repeat: no-repeat;\n}\n.assignment-offer__hide-btn[data-v-f8e0ebc8] {\n  background-color: #a7abbd;\n  color: #fff;\n}\n", ""]);
// Exports
module.exports = exports;
