<script>
  import SearchPrototype from '@/components/shared_components/form_fields/SearchPrototype';

  export default {
    extends: SearchPrototype,
    computed: {
      searchText() { return this.$gettext('Assignment nr…'); }
    }
  };
</script>

<style>
  .assignment-search__search-field .sk-input__field-icon {
    left: 10px;
  }

  .assignment-search__search-field .sk-input__input--with-icon {
    padding-right: 10px;
    padding-left: 40px;
  }
</style>
